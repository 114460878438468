.article {
    padding: 10px 90px 20px 90px;
    margin-left: 20%;
    min-height: calc(100vh - 170px);
  }
  .article-layout {
    padding-bottom: 10px;
    background: rgba(233, 227, 227, 0.363);
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 10px;
  
  }
  .article-title {
    width: 100%;
    height: 60px;
    font-size: 2em;
    line-height: 60px;
  }
  
  .article-time {
    font-size: 23px;
    border-bottom: 1px solid grey;
  }
  
  .article-detail {
    line-height: 1.6;
  }
  
  .article-detail h2 {
    margin: 1.2em 0px;
    font-size: 1.8em;
  }
  
  .article-detail h3 {
    margin: 1.2em 0;
    font-size: 1.6em;
  }
  
  .article-detail h4 {
    margin: 1.2em 0;
    font-size: 1.4em;
  }
  
  .article-detail p {
    margin: 1.2em 0;
    font-size: 1.2em;
  }
  
  .article-detail ul, ol {
    margin: 1.2em 0;
    padding-left: 1.2em;
  }
  
  .article-detail li {
    font-size: 1em;
    list-style-type: disc;
  }
  
  .article-detail blockquote {
    padding: 1px 10px;
    border-left: 2px solid black;
    background: rgb(200, 200, 200);
  }
  
  .article-detail p code, .article-detail ul code {
    display: inline-block;
    margin: 0 2px;
    padding: 0 4px;
    color: rgb(29, 31, 33);
    border-radius: 3px;
    line-height: 20px;
    background: rgb(200, 200, 200);
    border: 1px solid #ddd;
  }
  
  .article-detail pre code {
    display: block;
    margin: 20px 0px;
    overflow-x: auto;
    padding: 0.5em;
    background: rgb(29, 31, 33);
    color: rgb(200, 200, 200);
    padding: 10px 20px;
    font-family: Consolas;
    font-size: 1.2em;
    line-height: 1.5;
    border-radius: 10px;
  }
  
  .article-detail table {
    width: 100%;
    border: 1px solid;
    font-size: 1.2em;
    border-collapse: collapse;
  }
  
  .article-detail p>img {
    max-width: 80%;
    display: block;
    margin: 0 auto;
  }
  
  .article-detail th, .article-detail td {
    height: 50px;
    padding: 0px 20px;
    text-align: center;
    vertical-align: middle;
  }
  
  .article-detail table>tbody>tr:nth-child(odd)>td, .article-detail table>tbody>tr:nth-child(odd)>th {
    background-color: #f9f9f9;
  }
  
  .article-navigation-content {
    padding-left: 20px;
  }
  
  .article-navigation-content ul {
    padding: 5px;
  }
  
  .article-navigation-content li {
    margin-left: 20px;
    list-style-type: disc;
  }
  
  .article-comment-box {
    width: 98%;
    height: 50px;
    font-size: 1.3em;
    border-top: 1px solid rgba(0, 0, 0, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
  }
  
  .article-comment-box a {
    text-decoration: none;
    color: rgb(0, 0, 0);
  }