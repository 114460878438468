.posts {
  padding: 50px 40px 20px 120px;
  margin-left: 20%;
  min-height: calc(100vh - 170px);
}
.posts-top {
  font-size: 30px;
  height: 50px;
  line-height: 50px;
}
.posts-title {
  font-size: 20px;
  height: 30px;
  line-height: 30px;
}
.posts-time {
  color: black;
}
.posts-arrow {
  font-size:16px;
  color: grey;
}
