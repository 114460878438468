.tags {
    padding: 50px 40px 20px 120px;
    margin-left: 20%;
    min-height: calc(100vh - 170px);
  }
  .tags-top {
    font-size: 30px;
    height: 50px;
    border-bottom: 2px solid grey;
  }
  .tags-flex {
    display: flex;
  }
  .tags-list{
    width:130px;
    height:50px;
    margin:10px;
    line-height: 50px;
    border-radius: 30px;
  }
  .tags-list span{
    font-size: 24px;
    margin-left:15px; 
}
  