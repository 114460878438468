.about {
    padding: 50px 40px 20px 120px;
    margin-left: 20%;
    min-height: calc(100vh - 170px);
}
.about-me {
    margin-bottom: 20px;
    font-size: 24px;
}
.about-article {
    padding-bottom: 10px;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 10px;
    font-size: 18px;
}
.about-article p{
    margin-bottom: 20px;
}