.side {
    width: 20%;
    height: 100%;
    position: fixed;
    text-align: center;
    background: rgb(233, 233, 233);
}
.top {
    text-align: center;
    margin-top: 50%;
}
.logo {
    width: 100px;
    height: 100px;
    display: inline-block;
}
.logo img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.name {
    font-size: 25px;
    margin: 15px 0;
}
.text {
    font-size:18px;
    margin: 15px 0;
}
.nav {
    margin-top: 50px;
    text-align: center;
    font-size: 16px;
}
.nav li {
    margin: 10px 0;
    color: darkgray;
}
.nav li a:Link:hove{
    color: #ff0;
}
.litest li a:hove{
    display: block;
    background: #ff0;
}
.litest li a:hove{
    background: red;
}
.icons {
    text-align: center;
    margin-top: 50px;
}
.icons ul {
    display: inline-block;
}
.icons li{
    float: left;
    padding-left: 15px;

}

a:Link {
    text-decoration: none;
  }
