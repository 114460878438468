.home {
  padding: 30px 90px 20px 90px;
  margin-left: 20%;
  background: #fff;
  min-height: calc(100vh - 170px);
}
.home-list {
  background: rgba(233, 227, 227, 0.363);
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 10px;

}
.home-title {
  width: 100%;
  color: black;
  font-size: 25px;
  line-height: 50px;
}
.home-body {
  width: 100%;
  font-size: 20px;
  color: grey;
  padding-bottom: 5px;
}
.home-time {
  font-size: 20px;
  color: grey;
}
.home-paging{
  margin:20px;
  text-align: center;
}
